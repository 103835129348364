import axios from "axios";
import { HotspotDTO } from "../entities/hotspot.entity";
import getRandomFontClasses from "../utils/getRandomFontClasses";
import isEmpty from "lodash/isEmpty";
// import mockData from "./mock.data/past.hotspot.json";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const getPastHotspots = async () => {
  const { data: response } = await axios.get(
    API_BASE_URL + "/v1/hotspots/wall"
  );
  var hotspotsList = response?.data?.hotspots;

  if (isEmpty(hotspotsList)) {
    return [];
  }

  hotspotsList = fillToNextPerfectSquare(hotspotsList);

  const hotspots = hotspotsList?.map((h, i) => parseRawData(h, i));
  return hotspots ?? [];

  // Mock data
  // const response = mockData;
  // var hotspotsList = fillToNextPerfectSquare(response);
  // return hotspotsList.map((h, i) => parseRawData(h, i)) ?? [];
};

const parseRawData = (data, index) => {
  if (!data) {
    return null;
  }
  const params = {
    id: `${data.id}-${index}`,
    title: data.name,
    venue: data.venue,
    date: data.date,
    host: {
      name: data.host.name,
      profileImageUrl: data.host.profile_image_url,
    },
    inviteeCount: data.capacity,
    assets: data.assets,
    font: getRandomFontClasses(),
  };
  return new HotspotDTO(params);
};

const fillToNextPerfectSquare = (arr) => {
  // Calculate the next perfect square
  const length = arr.length;
  const nextPerfectSquare = Math.ceil(Math.sqrt(length)) ** 2;

  // Function to pick a random item from the array
  function getRandomItem(arr) {
    return arr[Math.floor(Math.random() * arr.length)];
  }

  // Fill the array until its length equals the next perfect square
  while (arr.length < nextPerfectSquare) {
    arr.push(getRandomItem(arr));
  }

  return arr;
};
