import useIntersectionObserver from "@react-hook/intersection-observer";
import { useEffect, useState } from "react";
import HotspotGridItemHeader from "./HotspotGridItemHeader";
import { WallCardImageLinearGradient } from "../../../constants/gradientDefinitions";
import HotspotInfo from "./HotspotInfo";
import useWallStore from "../../../store/useWallStore";
import { vibrateDevice, VIBRATION_TYPE } from "../../../utils/addVibration";
import { useNavigate } from "react-router-dom";

const HotspotGridItem = ({ hotspot, gridId, index }) => {
  const expandedHotspot = useWallStore((state) => state.expandedHotspot);

  const [target, setTarget] = useState(null);
  const { isIntersecting } = useIntersectionObserver(target, {
    threshold: 0.5,
  });
  const isCenter = gridId === 5 && index === 4; // TODO: Handle scroll to view logic using store

  const navigate = useNavigate();

  const handleCardClick = () => {
    if (!isIntersecting) {
      return;
    }

    // Open Hotspot Detail Page
    navigate(`/hotspot/${hotspot.id}`);
    // vibrateDevice(VIBRATION_TYPE.CLICK_HOTSPOT);
    return;
  };

  const isExpandedHotspotView = Boolean(expandedHotspot);
  const isActiveHotspot =
    expandedHotspot?.id === hotspot.id && expandedHotspot?.gridId === gridId;

  // On change of is active hotspot add a vibration
  // useEffect(() => {
  //   if (isIntersecting) {
  //     vibrateDevice(VIBRATION_TYPE.SCROLL_HOTSPOTS);
  //   }
  // }, [isIntersecting]);

  return (
    <div
      className={`hotspot-grid-item ${isActiveHotspot ? "active" : ""} ${
        isIntersecting ? "intersecting" : ""
      }`}
      style={{
        backgroundImage: `${WallCardImageLinearGradient}, url("${hotspot.bannerImgUrl}")`,
        borderRadius: "8px",
      }}
      ref={setTarget}
      onClick={isExpandedHotspotView ? null : handleCardClick}
      id={isCenter ? "center-hotspot-item" : ""}
    >
      <HotspotGridItemHeader
        type={hotspot.experience}
        hostName={hotspot.host.name}
        hostPic={hotspot.host.profileImageUrl}
        invitees={hotspot.inviteeCount}
      />
      <HotspotInfo
        title={hotspot.title}
        venue={hotspot.venue}
        date={hotspot.date}
        titleFont={hotspot.font}
      />
    </div>
  );
};

export default HotspotGridItem;
