import useWallStore from "../../store/useWallStore";
import HotspotGridItem from "./hotspotGridItem";

const HotspotPreviewGrid = ({ id }) => {
  const hotspotList = useWallStore((state) => state.hotspotList);

  return (
    <div
      className="wall-hotspot-grid"
      style={{
        gridTemplateColumns: `repeat(${Math.sqrt(hotspotList.length)}, 1fr)`,
      }}
    >
      {hotspotList.map((hotspot, i) => (
        <HotspotGridItem
          key={`${hotspot.id}-grid${id}`}
          hotspot={hotspot}
          gridId={id}
          index={i}
        />
      ))}
    </div>
  );
};

export default HotspotPreviewGrid;
