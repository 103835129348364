import { useEffect } from "react";
import "../styles/wall.scss";
import useWallStore from "../store/useWallStore";
import { ClimbingBoxLoader } from "react-spinners";
import WallDuplicateRender from "../components/wall/WallDuplicateRender";

const WallLanding = () => {
  const isLoading = useWallStore((state) => state.isLoading);
  const setLoading = useWallStore((state) => state.setLoading);
  const fetchHotspotList = useWallStore((state) => state.fetchHotspotList);

  const init = async () => {
    setLoading(true);
    await fetchHotspotList();
    setLoading(false);
  };

  useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) {
    return (
      <div className="center-container">
        <ClimbingBoxLoader color="#5961FF" size={16} />
      </div>
    );
  }

  return <WallDuplicateRender />;
};

export default WallLanding;
