import { create } from "zustand";
import { getPastHotspots } from "../apis/getPastHotspots";

const defaultState = {
  hotspotList: [],
  expandedHotspot: null,
  hotspotInView: null,
  isLoading: true,
};

const useWallStore = create((set, get) => ({
  ...defaultState,

  setHotspotList: (hotspotList) => set({ hotspotList }),

  setLoading: (isLoading) => set({ isLoading }),

  setExpandedHotspot: (expandedHotspot) => set({ expandedHotspot }),
  resetExpandedHotspot: () => set({ expandedHotspot: null }),

  setHotspotInView: (hotspotInView) => set({ hotspotInView }),

  fetchHotspotList: async () => {
    if (get().hotspotList.length) {
      return;
    }
    try {
      const hotspots = await getPastHotspots();
      set({ hotspotList: hotspots });
    } catch (e) {
      console.error(e);
    }
  },

  getHotspotById: async (id) => {
    let hotspots = get().hotspotList;
    if (!hotspots || !hotspots.length) {
      await get().fetchHotspotList();
      hotspots = get().hotspotList;
    }
    return hotspots.find((hotspot) => hotspot.id === id);
  },
}));

export default useWallStore;
