export const HotspotAssetPurposeEnum = {
  GALLERY: 1,
  BANNER: 2,
};

export class HotspotDTO {
  title;
  venue;
  date;
  host;
  inviteeCount;
  experience;
  bannerImgUrl;
  galleryImgUrls;
  id;
  font;

  constructor(params) {
    this.id = params.id;
    this.title = params.title;
    this.experience = params.title;
    this.venue = params.venue;
    this.date = params.date;
    this.host = new HostDTO(params.host);
    this.inviteeCount = params.inviteeCount;
    this.font = params.font;

    this.bannerImgUrl =
      params.assets[Math.floor(Math.random() * (params.assets.length - 1))].url;
    this.galleryImgUrls = [];
    this.galleryImgUrls = params.assets.map((asset) => asset.url);
  }
}

export class HostDTO {
  name;
  profileImageUrl;

  constructor(params) {
    this.name = params.name;
    this.profileImageUrl = params.profileImageUrl;
  }
}
