import HotspotPreviewGrid from "./HotspotPreviewGrid";
import WallHeader from "./WallHeader";

const WallDuplicateRender = () => {
  return (
    <div className="wall-landing-container">
      <WallHeader />
      {[...Array(9)].map((_, index) => (
        <HotspotPreviewGrid id={index + 1} key={index + 1} />
      ))}
    </div>
  );
};

export default WallDuplicateRender;
